<template>
  <div class="reservation-tab">
    <PageTitle title="" btn="新增預約" @btnClick="checkAuthPhoneAccount" />
    <FiltersContainer>
      <BaseElInput
        v-model="search.code"
        clearable
        placeholder="搜尋訂單編號"
        @keypress.enter.native="refresh"
        @clear="refresh"
      >
        <i slot="prefix" class="el-input__icon el-icon-search" @click="refresh" />
      </BaseElInput>

      <TableFilter
        :tableName="tableName"
        :options="memberReservationListFilterOptions"
        @update="updateTableFilter"
      />
    </FiltersContainer>

    <!-- <div class="flex justify-end"> -->

    <!-- </div> -->

    <BaseTable
      v-if="!tableRefresh"
      v-loading="loading"
      :data="reservationsList"
      empty-text="暫無數據"
    >
      <EmptyBlock slot="empty" />
      <BaseElTableColumn prop="code" label="訂單編號" width="120" fixed align="center" />
      <BaseElTableColumn v-if="showColumn('orderType', tableFilter)" prop="code" label="訂單類型" fixed align="center">
        <template slot-scope="scope">
          {{ getData(scope.row, 'AppointmentOrder.peopleCount', 1) > 1 ? '多人' : '單人' }}
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn v-if="showColumn('orderStatus', tableFilter)" prop="status" label="訂單狀態" align="center">
        <template slot-scope="scope">
          <Tag disable-transitions :type="orderStatus(getData(scope.row, 'AppointmentOrder.status'), 'tag')">
            {{ $t(orderStatus(getData(scope.row, 'AppointmentOrder.status'), 'name')) }}
          </Tag>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn
        v-if="showColumn('service', tableFilter)"
        prop="AppointmentService.name"
        label="服務項目"
        align="center"
      />
      <BaseElTableColumn
        v-if="showColumn('serviceUnit', tableFilter)"
        prop="AppointmentUnit.name"
        label="服務人員"
        align="center"
      />
      <BaseElTableColumn v-if="showColumn('date', tableFilter)" prop="start" label="預約日期" width="120" align="center">
        <template slot-scope="scope">
          {{ dateFormat(scope.row.start) }}
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn v-if="showColumn('userAddress', tableFilter) && useMemberAddress" prop="AppointmentOrder.userAddress" label="收件地點" align="center" width="180">
        <template slot-scope="scope">
          {{ scope.row.AppointmentOrder.userAddress || '-' }}
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn v-if="showColumn('origin', tableFilter)" prop="origin" label="預約來源" width="120" align="center">
        <template slot-scope="scope">
          {{ reservationOrigins(scope.row) }}
        </template>
      </BaseElTableColumn>

      <BaseElTableColumn v-if="showColumn('orderPrice', tableFilter) && useDeposit" label="訂單總額" prop="status" width="105" align="center">
        <template slot-scope="scope">
          {{ orderTotalPrice(scope.row) }}
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn v-if="showColumn('depositPrice', tableFilter) && useDeposit" label="應付訂金" prop="status" width="105" align="center">
        <template slot-scope="scope">
          {{ depositPrice(scope.row) }}
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn v-if="showColumn('paymentType', tableFilter) && useDeposit" label="付款方式" prop="status" width="150" align="center">
        <template slot-scope="scope">
          {{ depositPaymentType(scope.row) }}
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn
        v-if="showColumn('paymentStatus', tableFilter) && useDeposit"
        label="付款狀態"
        prop="status"
        width="105"
        align="center"
      >
        <template slot-scope="reservation">
          <Tag
            v-if="getData(reservation.row, 'AppointmentOrder.AppointmentOrderDeposit')"
            :type="paymentStatusTagType(reservation.row, 'deposit')"
            disable-transitions
          >
            {{ paymentStatus(reservation.row, 'deposit') }}
          </Tag>
          <span v-else>-</span>
        </template>
      </BaseElTableColumn>

      <BaseElTableColumn v-if="showColumn('checkoutTotalPrice', tableFilter)" label="實付金額" align="center">
        <template slot-scope="scope">
          {{ getData(scope.row, 'AppointmentOrder.AppointmentCheckoutOrder.totalPrice', '-') }}
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn v-if="showColumn('checkoutPaymentType', tableFilter)" label="結帳付款方式" width="120px" align="center">
        <template slot-scope="scope">
          {{ displayCheckoutPaymentType(scope.row) }}
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn
        v-if="showColumn('checkoutPaymentStatus', tableFilter)"
        label="結帳付款狀態"
        prop="status"
        width="105"
        align="center"
      >
        <template slot-scope="reservation">
          <Tag
            v-if="getData(reservation.row, 'AppointmentOrder.AppointmentCheckoutOrder.AppointmentCheckoutPayment')"
            :type="paymentStatusTagType(reservation.row, 'checkout')"
            disable-transitions
          >
            {{ paymentStatus(reservation.row, 'checkout') }}
          </Tag>
          <span v-else>-</span>
        </template>
      </BaseElTableColumn>

      <BaseElTableColumn fixed="right" label="操作" width="120" align="center">
        <template slot-scope="reservation">
          <BaseElButton
            slot="reference"
            type="text"
            icon="el-icon-setting"
            @click="onDetail(reservation.row)"
          />
        </template>
      </BaseElTableColumn>
    </BaseTable>

    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="reservationsCount"
      @pageChange="refresh"
    />

    <ReservationOrderDetail
      v-if="showInfo"
      :orderId="selectRow.id"
      :selecReservation="selectRow"
      @close="showInfo=false"
      @cancel="onCancel"
      @refresh="refresh"
    />

    <DeleteDialog
      v-if="deleteDialog"
      title="警示"
      :content="deleteContent"
      width="40%"
      btnString="取消預約"
      cancelBtnString="返回"
      @close="deleteDialog = false"
      @delete="cancelReservation(), deleteDialog=false"
    />
  </div>
</template>

<script>
import TableFilter from '@/components/Button/TableFilter.vue'
import ReservationOrderDetail from '@/components/Reservation/ReservationOrderDetail/ReservationOrderDetail.vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import DeleteDialog from '@/components/Dialog/DeleteDialog'
import MixinFunc from '@/components/MixinFunc.vue'

import { GetReservation, GetReservationCount, UpdateReservationStatus, UpdateReservationOrderStatus } from '@/api/reservation'
import { paymentOptionsConfig } from '@/config/payment'
import { getTableFilterConfig, showColumn } from '@/utils/tableFilter'
import { pageStartIndex } from '@/utils/table'
import { reservationOrderOrigins } from '@/utils/reservation'
import { statusTranslate } from '@/utils/helper'
import { getData } from '@/utils/object'

import { depositPaymentTypes, depositStatusConfig } from '@/config/deposit'
import { memberReservationListFilterOptions } from '@/config/table'
import { orderStatusConfig } from '@/config/reservation'
import { checkUserFeature } from '@/store/modules/permission'
import { mapGetters } from 'vuex'
import { get } from 'lodash'
import reservation from '@/mixin/reservation'
import { usePayment } from '@/use/usePayment'
import { useExternalWallet } from '@/use/useExternalWallet'
import { onMounted } from 'vue'
import { i18n } from '@/plugins/i18n/i18n'

export default {
  name: 'ReservationTab',
  components: {
    DeleteDialog,
    EmptyBlock,
    ReservationOrderDetail,
    TableFilter,
  },
  mixins: [MixinFunc],
  props: ['member'],
  setup () {
    const { getDisplayPaymentType } = usePayment()
    const { externalWalletName, getExternalWalletConfig } = useExternalWallet()

    const displayCheckoutPaymentType = (row) => {
      const checkoutOrder = get(row, 'AppointmentOrder.AppointmentCheckoutOrder')
      const checkoutPaymentType = get(checkoutOrder, 'AppointmentCheckoutPayment.paidType')
      const checkoutPaymentTypeComment = get(checkoutOrder, 'AppointmentCheckoutPayment.paidTypeComment')
      const displayCheckoutPaidType = getDisplayPaymentType({
        paidType: checkoutPaymentType,
        paidTypeComment: checkoutPaymentTypeComment,
      })
      if (checkoutPaymentType === 'externalWallet') return externalWalletName.value

      return displayCheckoutPaidType
    }
    onMounted(() => {
      getExternalWalletConfig()
    })
    return {
      paymentOptionsConfig,
      getDisplayPaymentType,
      displayCheckoutPaymentType,
      externalWalletName,
    }
  },

  data: () => ({
    tableRefresh: false,
    // memberReservationListFilterOptions,
    tableName: 'memberReservationTab',
    tableFilter: [],
    deleteContent: '',
    loading: false,
    showInfo: false,
    selectRow: {},
    deleteDialog: false,
    dialogType: '',
    reservationsCount: 0,
    reservationsList: [],
    tableOptions: {
      page: 1,
      pageLimit: 10,
    },
    search: {
      code: '',
      customFlowId: '',
    },
  }),
  computed: {
    ...mapGetters([
      'shop',
      'userPlanLimit',
      'userFeatures',
      'userPlanFeature',
    ]),
    shop () {
      return this.$store.getters.shop
    },
    pageStartIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },
    useDeposit () {
      return checkUserFeature(this.userPlanFeature, this.userFeatures, 'adminView.appointmentDeposit.enable')
    },
    memberReservationListFilterOptions () {
      const blockList = []
      if (!this.useDeposit) {
        blockList.push(...['orderPrice', 'depositPrice', 'paymentType', 'paymentStatus'])
      }
      if (!this.useMemberAddress) {
        blockList.push('userAddress')
      }
      return memberReservationListFilterOptions.filter(i => !blockList.includes(i.value))
      // return memberReservationListFilterOptions
    },
    useMemberAddress () {
      return this.checkAction('adminView.member.findUserInfoMoreAddress')
    },
  },

  async mounted () {
    this.tableFilter = getTableFilterConfig(this.tableName, memberReservationListFilterOptions)
    await this.refresh()
  },
  methods: {
    showColumn,
    getData,
    onDetail (row) {
      this.selectRow = row
      this.showInfo = true
    },
    checkAction (action) {
      return checkUserFeature(this.userPlanFeature, this.userFeatures, action)
    },
    orderStatus (status, attr) {
      return get(orderStatusConfig[status], attr)
    },
    onCancel (data) {
      const orders = get(data, 'AppointmentOrder.AppointmentReservations')
      console.log(orders)
      const orderCode = get(data, 'AppointmentOrder.code')
      // const numberCodes = map(orders, 'numberCode')
      // const totalOrde = max(numberCodes)
      // this.deleteDialog = true
      if (!orders) {
        this.deleteDialog = true
        this.deleteContent = '確定要取消此筆預約？'
        return
      }
      if (!orders.length || orders.length === 1) {
        this.deleteDialog = true
        this.deleteContent = '確定要取消此筆預約？'
        return
      }

      const ordersCount = orders.length

      this.deleteContent = `訂單編號 ${orderCode} 有 ${ordersCount} 筆預約紀錄，將同時取消 ${ordersCount} 筆預約紀錄。`
      this.deleteDialog = true
    },
    async refresh () {
      this.loading = true
      this.getReservation()
      this.getReservationCount()
      this.loading = false
    },

    reservationTagType (val) {
      let type = 'info'
      if (val === 'complete') type = 'action'
      if (val === 'wait') type = 'info'
      if (val === 'cancel') type = 'danger'
      return type
    },
    statusTrans (status) {
      return statusTranslate(status)
    },
    reservationOrigins (row) {
      const notSpecify = row.fromNotSpecify
      const origin = getData(row, 'AppointmentOrder.origin', '')
      const originText = getData(row, 'AppointmentOrder.originText', '')
      return reservationOrderOrigins({ notSpecify, origin, originText })
    },

    //= > 取得預約總數
    async getReservationCount () {
      try {
        this.reservationsCount = await GetReservationCount({
          shopId: this.shop,
          code: (this.search.code === '') ? undefined : this.search.code,
          MemberId: this.member.id,
          // customFlowNodeId: this.search.customFlowId || undefined,
        })
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    //= > 取得預約
    async getReservation () {
      try {
        const res = await GetReservation({
          shopId: this.shop,
          start: this.pageStartIndex,
          limit: this.tableOptions.pageLimit,
          MemberId: this.member.id,
          code: (this.search.code === '') ? undefined : this.search.code,
          includeAppointmentOrderCheckIn: this.checkAction('admin.checkInPageSetting.page'),
          includeAppointmentReservationTicket: this.checkAction('admin.classTicket.page'),
          // includeCustomFlowRecord: this.useCustomFlowFeature.record,
          includeAppointmentOrderDeposit: showColumn('depositPaymentStatus', this.tableFilter) || showColumn('depositPrice', this.tableFilter),
          includeAppointmentCheckoutOrder: showColumn('checkoutPaymentStatus', this.tableFilter),
          // customFlowNodeId: this.search.customFlowId || undefined,
        })

        this.reservationsList = res.filter(
          (item) => item.MemberId === this.member.id,
        )
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    //= > 取消預約
    // async cancelReservation () {
    //   try {
    //     await UpdateReservationStatus({
    //       shopId: this.shop,
    //       id: this.selectRow.id,
    //       status: 'cancel',
    //     })
    //     this.$message.success('取消預約成功')
    //     this.deleteDialog = false
    //     this.showInfo = false
    //     this.refresh()
    //   } catch (error) {
    //     console.log(error)
    //     this.$message.error({
    //       message: error || error.message,
    //     })
    //   }
    // },

    //= > 刪除(取消)預約
    async cancelReservation () {
      const [, err] = await UpdateReservationOrderStatus({
        shopId: this.shop,
        id: get(this.selectRow, 'AppointmentOrder.id'),
        status: 'cancel',
      })

      if (err) return this.$message.error(err)
      this.$message.success('取消預約成功')
      this.deleteDialog = false
      this.showInfo = false
      this.refresh()
    },

    //= > 檢查是否有綁定手機
    checkAuthPhoneAccount () {
      if (!this.member.UserInfo.phone || this.member.UserInfo.phone === '') return this.$message.error('該會員無綁定手機號碼無法進行預約')
      else this.$router.push({ name: 'NewReservationCreate', query: { member: this.member.id } })
    },

    async updateTableFilter () {
      // this.loading = true
      this.tableRefresh = true
      this.tableFilter = getTableFilterConfig(this.tableName)
      await this.refresh()
      setTimeout(() => {
        this.tableRefresh = false
      }, 0)
      // this.loading = false
    },

    orderTotalPrice (row) {
      const orderPrice = get(row, 'AppointmentOrder.totalPrice')
      if (!orderPrice) return '-'
      return `$ ${orderPrice}`
    },

    depositPaymentType (row) {
      const depositData = get(row, 'AppointmentOrder.AppointmentOrderDeposit')
      if (!depositData) return '-'
      const type = depositData.paidType
      if (type === 'offline') {
        const comment = depositData.paidTypeComment
        return i18n.t(get(depositPaymentTypes[comment], 'name'))
      }
      if (type === 'externalWallet') {
        return this.externalWalletName
      }
      return i18n.t(get(depositPaymentTypes[type], 'name'))
    },

    depositPrice (row) {
      const depositData = get(row, 'AppointmentOrder.AppointmentOrderDeposit')
      if (!depositData) return '-'
      if (depositData.depositPrice === 0) return '-'
      return `$ ${depositData.depositPrice}`
    },
    paymentStatus (row, type) {
      if (type === 'deposit') {
        const depositData = get(row, 'AppointmentOrder.AppointmentOrderDeposit')
        if (!depositData) return '-'
        const status = depositData.status
        return i18n.t(get(depositStatusConfig[status], 'name'))
      } else if (type === 'checkout') {
        const depositData = get(row, 'AppointmentOrder.AppointmentCheckoutOrder.AppointmentCheckoutPayment')
        if (!depositData) return '-'
        const status = depositData.status
        return i18n.t(get(depositStatusConfig[status], 'name'))
      }
    },
    paymentStatusTagType (row, type) {
      if (type === 'deposit') {
        const depositData = get(row, 'AppointmentOrder.AppointmentOrderDeposit')
        if (!depositData) return ''
        const status = depositData.status
        return get(depositStatusConfig[status], 'tag')
      } else if (type === 'checkout') {
        const checkoutData = get(row, 'AppointmentOrder.AppointmentCheckoutOrder.AppointmentCheckoutPayment')
        if (!checkoutData) return ''
        const status = checkoutData.status
        return get(depositStatusConfig[status], 'tag')
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
