<template>
  <div class="order-info">
    <BaseElForm v-if="order" label-width="150px" label-position="left">
      <!-- <p class="section-title text-primary-100 font-medium">{{ index }}.預約訂單</p> -->
      <BaseElFormItem :label="$t('common.table.action.text')">
        <BaseElButton class="primary-btn underline" type="text" :disabled="loading" @click="showEditDialog = true">
          {{ $t('orderInfoBlock.button.editOrder.text')}}
        </BaseElButton>
      </BaseElFormItem>
      <BaseElFormItem :label="$t('orderInfoBlock.code.title')">
        <div>{{ order.code }}</div>
      </BaseElFormItem>
      <BaseElFormItem :label="$t('orderInfoBlock.totalPrice.title')">
        <div>{{ displayCurrencySymbol }} {{ order.totalPrice }}</div>
      </BaseElFormItem>
      <BaseElFormItem :label="$t('orderInfoBlock.status.title')">
        <Tag
          v-if="getData(order, 'AppointmentOrder.status', '')"
          style="display: inline-flex"
          :type="orderStatus(getData(order, 'AppointmentOrder.status'), 'tag')"
          disable-transitions
        >
          {{ $t(orderStatus(getData(order, 'AppointmentOrder.status'), 'name')) }}
          <!-- {{order.status}} -->
        </Tag>
      </BaseElFormItem>

      <BaseElFormItem :label="$t('orderInfoBlock.memberType.title')">
        <div>
          <span class="mr-3">{{ memberType(order) }}</span>
          <BaseElButton
            v-if="order.Member && $route.name !== 'MemberProfile'"
            type="text"
            class="table-opt-edit"
            @click="$router.push(`/members/member-profile/${order.MemberId}`)"
          >
            {{ $t('orderInfoBlock.button.goProfile.text')}}
          </BaseElButton>
        </div>
      </BaseElFormItem>

      <BaseElFormItem :label="$t('orderInfoBlock.peopleCount.title')">
        <div>{{ peopleCount }}</div>
      </BaseElFormItem>
      <BaseElFormItem v-if="isPubApt" :label="$t('orderInfoBlock.user.email.title')">
        <div>{{ displayData.email }}</div>
      </BaseElFormItem>
      <BaseElFormItem :label="$t('orderInfoBlock.user.name.title')">
        <div>{{ displayData.name }}</div>
      </BaseElFormItem>
      <BaseElFormItem :label="$t('orderInfoBlock.user.birthday.title')">
        <div>{{ displayData.birthday }}</div>
      </BaseElFormItem>
      <BaseElFormItem :label="$t('orderInfoBlock.user.phone.title')">
        <div>{{ displayData.phone }}</div>
      </BaseElFormItem>
      <BaseElFormItem v-if="isPubApt" :label="$t('orderInfoBlock.user.gender.title')">
        <div>{{ displayData.gender }}</div>
      </BaseElFormItem>
      <BaseElFormItem v-if="useMemberAddress" :label="$t('orderInfoBlock.address.title')">
        <div>{{ displayData.address }}</div>
      </BaseElFormItem>

      <BaseElFormItem :label="$t('orderInfoBlock.comment.title')">
        <div style="width: 100%; word-wrap: break-word">
          {{ displayData.comment || $t('common.none.text') }}
        </div>
      </BaseElFormItem>
      <BaseElFormItem :label="$t('orderInfoBlock.shopNote.title')">
        <div>{{ displayData.shopNote || $t('common.none.text') }}</div>
      </BaseElFormItem>
      <BaseElFormItem :label="$t('orderInfoBlock.origin.title')">
        <div>{{ reservationOrigins }}</div>
      </BaseElFormItem>
    </BaseElForm>

    <EditReservationStatusDialog
      v-if="showEditDialog"
      :data="order"
      @close="showEditDialog = false"
      @refresh="$emit('refresh')"
    />
  </div>
</template>

<script>
import { computed } from 'vue'
import EditReservationStatusDialog from '@/components/Reservation/EditReservationDialog/EditReservationStatusDialog.vue'
import { reservationOrderOrigins } from '@/utils/reservation'
import { get } from 'lodash'
import { getData } from '@/utils/object'
import { orderStatusConfig } from '@/config/reservation'
import { checkUserFeature } from '@/store/modules/permission'
import { mapGetters } from 'vuex'
import { formatDate } from '@/utils/date'
import { genderName } from '@/config/userInfoOptions'
import { i18n } from '@/plugins/i18n/i18n'
import { useCurrency } from '@/use/useCurrency'

export default {
  name: 'ReservationOrderInfoBlock',
  components: {
    EditReservationStatusDialog,
  },
  props: {
    loading: Boolean,
    order: {
      type: Object,
      default: () => ({}),
    },
    index: Number,
  },
  setup (props, { emit }) {
    const { displayCurrencySymbol } = useCurrency()
    const isPubApt = computed(() => get(props.order, 'AppointmentOrder.origin') === 'pubApt')
    const displayData = computed(() => {
      const order = props.order
      const AppointmentVisitorPreOrder = get(order, 'AppointmentOrder.AppointmentVisitorPreOrder')
      const name = isPubApt.value
        ? `${get(AppointmentVisitorPreOrder, 'profile.firstName')} ${get(
          AppointmentVisitorPreOrder,
          'profile.lastName',
        )}`
        : get(order, 'AppointmentOrder.userName', '-')
      const birthday = isPubApt.value
        ? get(AppointmentVisitorPreOrder, 'profile.birthDate')
        : get(order, 'Member.UserInfo.birthday')
      const phone = isPubApt.value
        ? `${get(AppointmentVisitorPreOrder, 'profile.phoneCountryCode')} ${get(
          AppointmentVisitorPreOrder,
          'profile.phoneNumber',
        )}`
        : get(order, 'AppointmentOrder.userPhone', '-')
      const address = get(order, 'AppointmentOrder.userAddress', '-')
      const comment = get(order, 'AppointmentOrder.userComment', '無')
      const shopNote = get(order, 'AppointmentOrder.adminComment', '無')
      const email = isPubApt.value
        ? get(AppointmentVisitorPreOrder, 'Visitor.email', '-')
        : undefined
      const gender = isPubApt.value
        ? get(genderName, get(AppointmentVisitorPreOrder, 'profile.gender', '-'))
        : undefined
      return {
        name,
        birthday: birthday ? formatDate(birthday, 'YYYY/MM/DD') : '-',
        phone,
        address,
        comment,
        shopNote,
        email,
        gender,
      }
    })
    const chargeType = computed(() => {
      return get(props.order, 'AppointmentOrder.chargeType')
    })
    const memberType = (order) => {
      if (order.Member) {
        return i18n.t('orderInfoBlock.memberType.member.title')
      } else if (isPubApt.value) {
        return i18n.t('orderInfoBlock.memberType.isPubApt.title')
      } else {
        return i18n.t('orderInfoBlock.memberType.nonMember.title')
      }
    }
    return { displayData, isPubApt, memberType, chargeType, displayCurrencySymbol }
  },
  data: () => ({
    showEditDialog: false,
  }),
  computed: {
    ...mapGetters(['shop', 'userPlanLimit', 'userFeatures', 'userPlanFeature']),
    isCancel () {
      return getData(this.order, 'AppointmentOrder.status') === 'cancel'
    },
    peopleCount () {
      return get(this.order, 'AppointmentOrder.peopleCount', 1)
    },
    reservationOrigins () {
      const notSpecify = this.order.fromNotSpecify
      const origin = get(this.order, 'AppointmentOrder.origin', '')
      const originText = get(this.order, 'AppointmentOrder.originText', '')
      return reservationOrderOrigins({ notSpecify, origin, originText })
    },
    useMemberAddress () {
      return this.checkAction('adminView.member.findUserInfoMoreAddress')
    },
  },
  methods: {
    getData,
    checkAction (action) {
      return checkUserFeature(this.userPlanFeature, this.userFeatures, action)
    },
    orderStatus (status, attr) {
      return get(orderStatusConfig[status], attr)
    },
    statusContent (status) {
      const statusDict = {
        cancel: '已取消',
        wait: '已預約',
        complete: '完　成',
        absence: '未　到',
      }
      return statusDict[status] || 'N/A'
    },
  },
}
</script>

<style scoped lang="scss"></style>
