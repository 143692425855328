<template>
  <div
    v-if="usePermission || givePermission"
    v-loading="loading"
    class="use-rebate-block"
  >
    <BaseElForm
      ref="formRef"
      label-position="left"
      label-width="100px"
      :model="formData"
      :rules="formRules"
    >
      <BaseElFormItem :label="displayData.formItemlabel.type" prop="type">
        <BaseElRadioGroup v-model="formData.type" @change="onTypeChange">
          <BaseElRadio v-if="usePermission" label="use">{{ displayData.btn.use }}</BaseElRadio>
          <BaseElRadio v-if="givePermission" label="add">{{ displayData.btn.give }}</BaseElRadio>
        </BaseElRadioGroup>
      </BaseElFormItem>
      <BaseElFormItem :label="displayData.formItemlabel.amount" prop="amount">
        <BaseElInput v-model="formData.amount" type="number" :placeholder="displayData.placeholder.amount" />
      </BaseElFormItem>
      <BaseElFormItem :label="displayData.formItemlabel.comment" prop="commnet">
        <BaseElInput v-model="formData.comment" :placeholder="displayData.placeholder.comment" />
      </BaseElFormItem>
      <BaseElFormItem>
        <div>
          <BaseElButton @click="onCancel">
            {{ displayData.btn.cancel }}
          </BaseElButton>
          <BaseElButton type="primary" @click="handleRebateWallet">
            {{ displayData.btn.save }}
          </BaseElButton>
        </div>
      </BaseElFormItem>
    </BaseElForm>
  </div>
</template>

<script>
import { reactive, ref, computed, defineComponent, inject } from 'vue'
import { maxNumberRules, minRules, noEmptyRules, rangeRules } from '@/validation'
import { AddPointWalletRecord, UsePointWalletRecord } from '@/api/shopPoint'
import store from '@/store'
import formUtils from '@/utils/form'
import { debounce, get, find } from 'lodash'
import { usePermissions } from '@/use/permissions'
import notifyMessage from '@/config/notifyMessage'

export default defineComponent({
  name: 'UsePointBlock',
  props: {
    memberId: {
      type: String,
    },
    pointBalance: {
      type: Object,
      default: () => ({}),
    },
    shopPointKey: {
      type: String,
    },
  },
  setup (props, { emit }) {
    const pageShopPoint = inject('pageShopPoint')
    const { checkAction } = usePermissions()

    const loading = ref(false)
    const formRef = ref(null)
    const shopId = computed(() => store.getters.shop)

    const usePermission = computed(() => {
      return checkAction('admin.member.usePointWalletRecord')
    })
    const givePermission = computed(() => {
      return checkAction('admin.member.addPointWalletRecord')
    })

    // >> 表單資料
    const formData = reactive({
      type: null,
      amount: null,
      comment: null,
    })

    // ?? 表單驗證規則
    const formRules = computed(() => {
      const rules = {
        type: [noEmptyRules()],
        amount: [noEmptyRules()],
      }
      if (formData.type === 'use') {
        const balance = get(props.pointBalance, `${props.shopPointKey}.balance`, 0)
        if (!balance) {
          rules.amount.push(minRules(0))
          rules.amount.push(maxNumberRules(balance))
        } else {
          rules.amount.push(rangeRules(1, balance))
        }
      } else if (formData.type === 'add') {
        rules.amount.push(minRules(1))
      }
      return rules
    })

    // ?? 頁面顯示資料
    const displayData = computed(() => {
      const data = {
        btn: {
          use: '扣除',
          give: '贈送',
          cancel: '取消',
          save: '儲存',
        },
        formItemlabel: {
          type: '操作',
          amount: get(pageShopPoint.value, 'name', '點數'),
          comment: '備註',
        },
        placeholder: {
          amount: '請輸入數量',
          comment: '請輸入備註',
        },
      }
      return data
    })

    const onCancel = () => {
      formData.type = null
      formData.amount = null
      formData.comment = null
      setTimeout(() => {
        formUtils.clearValidate(formRef.value)
      }, 30)
    }

    const onTypeChange = () => {
      setTimeout(() => {
        formUtils.clearValidate(formRef.value)
      }, 30)
    }

    const handleRebateWallet = debounce(async () => {
      if (loading.value) return
      loading.value = true
      if (!(await formUtils.checkForm(formRef.value))) {
        loading.value = false
        return
      }
      const type = formData.type
      const methods = {
        add: AddPointWalletRecord,
        use: UsePointWalletRecord,
      }
      const [, err] = await methods[type]({
        shopId: shopId.value,
        memberId: props.memberId,
        originMessage: formData.comment || undefined,
        addAmount: type === 'add' ? formData.amount : undefined,
        useAmount: type === 'use' ? formData.amount : undefined,
        shopPointKey: props.shopPointKey,
      })
      loading.value = false
      if (err) return (window.$message.error(err))
      emit('refresh')
      window.$message.success(notifyMessage.operationSuccess)
      onCancel()
    }, 300, { leading: false, trailing: true })

    return {
      loading,
      displayData,
      formData,
      formRules,
      formRef,
      onCancel,
      handleRebateWallet,
      usePermission,
      givePermission,
      pageShopPoint,
      onTypeChange,
    }
  },
})

</script>

<style scoped lang="postcss">

</style>
